<template>
    <div class="form-group row mt-8">

        <div class="col-lg-6 mb-5">
            <label>{{$t('sales_invoices.name')}}</label>
            <input v-model="data.name" type="text" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
            <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.name[0] }}
                </span>
        </div>
        <div class="col-lg-6 mb-5">
            <label>{{$t('sales_invoices.attachment')}}</label>
            <upload-file
                    @file="listenerAttachment"
                    :inner-id="'attachment'"
                    :placeholder="$t('sales_invoices.upload_attachment')"
                    :upload="dir_upload"
                    :start-link="'base'"
                    v-bind:valueprop="{name:'attachment',path:attachment_url}" v-if="reloadUploadAttachment">
            </upload-file>
            <span v-if="validation && validation.attachment" class="fv-plugins-message-container invalid-feedback">
                {{ validation.attachment[0] }}
            </span>
        </div>
        <div class="col-lg-6 mb-5">
            <label>{{$t('sales_invoices.notes')}}</label>
            <textarea type="text" v-model="data.notes" class="form-control" :class="  validation && validation.notes ? 'is-invalid' : ''"></textarea>
            <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                {{ validation.notes[0] }}
            </span>
        </div>

        <div class="col-lg-12 mb-8 text-center">
            <button type="button" class="btn btn-primary m-2" @click="save">{{ $t('save') }}</button>
        </div>
        <div class="col-lg-12 mb-5 mt-15">
            <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="tableAttachment">

                <template slot="attachment_url" slot-scope="props">
                    <a :href="props.row.attachment_url" target="_blank">{{ $t('link') }}</a>
                </template>
                <template slot="actions" slot-scope="props">
                    <v-icon small class="text-info mr-2" @click="editItem(props.row)">mdi-pencil</v-icon>
                    <v-icon small class="text-danger" @click="deleteItem(props.row)">mdi-delete</v-icon>
                </template>
            </v-server-table>
        </div>
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";

    export default {
        name: "Attachments",
        props:['salesInvoiceId'],
        data() {
            return {
                mainRoute: 'sales/sales_refund_attachments',
                dir_upload: 'sales_invoices',

                data: {
                    sales_invoice_refund_id: null,
                    name: null,
                    attachment: null,
                    notes: null,
                },
                attachment_url: null,
                validation: null,
                reloadUploadAttachment: true,
                columns: ['name', 'attachment_url', 'notes', 'actions'],
                idEditing: null,
            };
        },
        watch:{
            salesInvoiceId: function (val) {
                if (val){
                    this.data.sales_invoice_refund_id = val;
                }
            }
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        name: that.$t('sales_invoices.name'),
                        attachment_url: that.$t('sales_invoices.attachment'),
                        notes: that.$t('sales_invoices.notes'),
                        actions: that.$t('actions'),

                    },

                    filterByColumn: false,
                    customFilters: [],
                    filterable: false,
                    orderBy: {'column': 'id'},
                    sortable: [],
                    alwaysShowPerPageSelect: false,
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                          perPage: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            // filter: data.query,
                            sales_invoice_refund_id: that.salesInvoiceId,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        methods: {
            getFetch() {
                this.$refs.tableAttachment.refresh();
            },
            save() {
                if (this.isEditing) {
                    this.update();
                } else {
                    this.create();
                }
            },

            create() {
                ApiService.post(`${this.mainRoute}`, {
                    ...this.data,
                })
                    .then((response) => {
                        this.validation = null;
                        this.$successAlert(response.data.message);
                        this.afterSave();
                        this.getFetch();
                    })
                    .catch((error) => {
                        this.$errorAlert(error);
                        this.validation = error.response ? error.response.data.errors : null;
                    });
            },


            update() {
                ApiService.put(`${this.mainRoute}/${this.idEditing}`, {
                    ...this.data,
                })
                    .then((response) => {
                        this.validation = null;
                        this.$successAlert(response.data.message);
                        this.afterSave();
                        this.getFetch();
                    })
                    .catch((error) => {
                        this.$errorAlert(error);
                        this.validation = error.response ? error.response.data.errors : null;
                    });
            },
            editItem(item) {
                this.idEditing = item.id;
                this.reloadUploadAttachment = false;
                ApiService.get(this.mainRoute + "/" + item.id).then((response) => {
                    this.isEditing = true;
                    this.data.sales_invoice_refund_id = response.data.data.sales_invoice_refund_id;
                    this.data.name = response.data.data.name;
                    this.data.attachment = response.data.data.attachment;
                    this.data.notes = response.data.data.notes;
                    this.attachment_url = response.data.data.attachment_url;
                    this.reloadUploadAttachment = true;
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            afterSave() {
                this.attachment_url = null;
                this.reloadUploadAttachment = false;
                this.data = {
                    sales_invoice_refund_id: this.salesInvoiceId,
                    name: null,
                    attachment: null,
                    notes: null,
                };
                setTimeout(()=>{
                    this.reloadUploadAttachment = true;
                },100)

                this.idEditing = null;
                this.isEditing = false;
            },
            listenerAttachment(event) {
                if (event) {
                    this.attachment_url = event.pathDB;
                    this.data.attachment = event.name;
                }
            },
        },
        mounted() {
            this.data.sales_invoice_refund_id = this.salesInvoiceId;
        },
    }
</script>

<style scoped>

</style>