<template>
    <div class="mt-3">

      <table class="table">
        <thead>
          <tr>
            <th>{{$t('inventory')}}</th>
            <th>{{$t('item')}}</th>
            <th>{{$t('qty_before')}}</th>
            <th>{{$t('qty_after')}}</th>
            <th>{{$t('created_at')}}</th>
          </tr>
        </thead>
        <tbody>
        <tr  v-for="(row, index) in data" :key="index">
          <td>{{row.inventory_name}}</td>
          <td>{{row.item_name}}</td>
          <td>
            <p v-if="row.affected_columns_old_value && row.affected_columns_old_value.stock_on_hand_qty"><span>{{$t('stock_on_hand_qty')}}: </span><span>{{row.affected_columns_old_value? row.affected_columns_old_value.stock_on_hand_qty : 0}}</span></p>
            <p v-if="row.affected_columns_old_value && row.affected_columns_old_value.available_stock_qty"><span>{{$t('available_stock_qty')}}: </span><span>{{row.affected_columns_old_value? row.affected_columns_old_value.available_stock_qty : 0}}</span></p>
          </td>
          <td>
            <p v-if="row.affected_columns_new_value && row.affected_columns_new_value.stock_on_hand_qty"><span>{{$t('stock_on_hand_qty')}}: </span><span>{{row.affected_columns_new_value? row.affected_columns_new_value.stock_on_hand_qty : 0}}</span></p>
            <p v-if="row.affected_columns_new_value && row.affected_columns_new_value.available_stock_qty"><span>{{$t('available_stock_qty')}}: </span><span>{{row.affected_columns_new_value? row.affected_columns_new_value.available_stock_qty : 0}}</span></p>
          </td>
          <td>{{row.created_at}}</td>
        </tr>
        </tbody>
      </table>

    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";
    export default {
        name: "StatisticsLogInventory",
        props:['innerKey','id'],
        data(){
            return {
                mainRoute: 'base/statistics_log_inventory',
                data:{},
            }
        },
        methods:{
            async getData() {
                ApiService.get(`${this.mainRoute}/${this.innerKey}/${this.id}`).then((response) => {
                    this.data = response.data.data;
                });
            },
        },
        mounted() {
            if (this.id) {
                this.getData();
            }

        },
    }
</script>

<style scoped>

</style>